import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Gallery1 from "../img/Gallery1.jpg";
import Gallery2 from "../img/Gallery2.jpg";
import Gallery3 from "../img/Gallery3.jpg";
import Gallery4 from "../img/Gallery4.jpg";
import Gallery5 from "../img/Gallery5.jpg";
import Gallery6 from "../img/Gallery6.jpg";
import AnimatedDiv from "./AnimatedDiv";

const Gallery = () => {
  const PrevArrow = ({ onClick }) => (
    <button className="carousel-arrow prev" onClick={onClick}>
      <i className="fa fa-angle-left carousel-icon" aria-hidden="true"></i>
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button className="carousel-arrow next" onClick={onClick}>
      <i className="fa fa-angle-right carousel-icon" aria-hidden="true"></i>
    </button>
  );

  const settings = {
    autoplay: false,
    speed: 1500,
    dots: false,
    infinite: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="container-fluid bg-gallery"
      id="gallery"
      style={{ padding: "120px 0", margin: "90px 0" }}
    >
      <AnimatedDiv>
      <div
        className="section-title position-relative text-center"
        style={{ marginBottom: "120px" }}
      >
        <h6
          className="text-uppercase text-primary mb-3"
          style={{ letterSpacing: "3px" }}
        >
          Nuestros momentos juntos
        </h6>
        <h1 className="font-secondary display-4 text-white">Galería</h1>
        <i className="far fa-heart text-white"></i>
      </div>
      </AnimatedDiv>
      <Slider
        {...settings}
        className="gallery-carousel"
        style={{ marginTop: "-30px" }}
      >
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery1} alt="" />
          <a href={Gallery1} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery2} alt="" />
          <a href={Gallery2} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery3} alt="" />
          <a href={Gallery3} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery4} alt="" />
          <a href={Gallery4} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery5} alt="" />
          <a href={Gallery5} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
        <div className="gallery-item">
          <img className="img-fluid w-100" src={Gallery6} alt="" />
          <a href={Gallery6} data-lightbox="gallery">
            <i className="fas fa-search-plus text-white"></i>
          </a>
        </div>
      </Slider>
    </div>
  );
};

export default Gallery;
