import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import Groom from "../img/Groom.jpg";
import Bride from "../img/Bride.jpg";

const Invitation = () => {
  return (
    <div className="container-fluid py-5" id="invitation">
      <div className="container py-5">
        <AnimatedDiv>
          <div className="section-title position-relative text-center">
            <h1 className="font-secondary display-4 mb-3">¡Hola!</h1>
            <h6 style={{ letterSpacing: "2px", color: "#474747" }}>
              Te invitamos a celebrar con nosotros nuestra boda el día
            </h6>
            <h6
              className="text-uppercase text-primary mb-3 mt-3"
              style={{ letterSpacing: "3px" }}
            >
              13 de enero de 2024
            </h6>
            <i className="far fa-heart text-dark"></i>
          </div>
        </AnimatedDiv>
        <div className="row m-0 mb-4 mb-md-0 pb-2 pb-md-0">
          <div className="col-md-6 p-0 text-center text-md-right">
            <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-5">
              <h3 className="mb-3">El novio</h3>
              <p>
                Es Ingeniero Agrónomo. Tucumano. Es un apasionado del fútbol,
                deporte que le inculcó su papá desde chico y pasión que
                comparten. Es el "asador del grupo", el que nunca falta a una
                reunión.
              </p>
              <h3 className="font-secondary font-weight-normal text-muted mb-3">
                <i className="fa fa-male text-primary pr-3"></i>Federico García
              </h3>
            </div>
          </div>
          <div className="col-md-6 p-0" style={{ minHeight: "400px" }}>
            <img
              alt="groom"
              className="w-100 h-100"
              src={Groom}
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
        <div className="row m-0">
          <div className="col-md-6 p-0" style={{ minHeight: "400px" }}>
            <img
              alt="bride"
              className="w-100 h-100"
              src={Bride}
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-md-6 p-0 text-center text-md-left">
            <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-5">
              <h3 className="mb-3">La novia</h3>
              <p>
                Es profesora de matemática. Cordobesa. Amante de la naturaleza y
                de los animales. Ama a sus amigas y haría todo por ellas. Es una
                apasasionada de la música, por lo que en su tiempo libre toma
                clases de canto.
              </p>
              <h3 className="font-secondary font-weight-normal text-muted mb-3">
                <i className="fa fa-female text-primary pr-3"></i>Valentina
                Posse
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitation;
