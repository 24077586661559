import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import Story1 from "../img/Story1.jpg";
import Story2 from "../img/Story2.jpg";
import Story3 from "../img/Story3.jpg";
import Story4 from "../img/Story4.jpg";

const Story = () => {
  return (
    <div className="container-fluid py-5" id="story">
      <div className="container pt-5 pb-3">
      <AnimatedDiv>
        <div className="section-title position-relative text-center">
          <h6
            className="text-uppercase text-primary mb-3"
            style={{ letterSpacing: "3px" }}
          >
            Un poco de
          </h6>
          <h1 className="font-secondary display-4">Nuestra historia</h1>
          <i className="far fa-heart text-dark"></i>
        </div>
        </AnimatedDiv>
        <div className="container timeline position-relative p-0">
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <img className="img-fluid mr-md-3" src={Story1} alt="" />
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                <h4 className="mb-2">Nos conocimos</h4>
                <p className="text-uppercase mb-2">14 de enero de 2018</p>
                <p className="m-0">
                  Ambos trabajábamos para la misma empresa pero nunca antes nos
                  habíamos cruzado. Nos vimos por primera vez y comenzamos a
                  hablar luego de una cena de fin de año organizada por la
                  empresa.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 mr-md-3">
                <h4 className="mb-2">Nuestra primera cita</h4>
                <p className="text-uppercase mb-2">20 de enero de 2018</p>
                <p className="m-0">
                  Luego de largos intercambios de mensajes y, por insistencia de
                  nuestros amigos, salimos por primera vez. Fuimos a merendar a
                  una pastelería de nuestra ciudad y la chispa estaba cada vez
                  más encendida. La merienda se estiró y terminamos cenando
                  juntos también.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <img className="img-fluid ml-md-3" src={Story2} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <img className="img-fluid mr-md-3" src={Story3} alt="" />
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 ml-md-3">
                <h4 className="mb-2">Somos novios</h4>
                <p className="text-uppercase mb-2">26 de mayo de 2018</p>
                <p className="m-0">
                  Luego de unos meses volvimos a la vieja cafetería donde
                  tuvimos nuestra primera cita. Fue entonces cuando Federico
                  propuso que fuéramos novios. Desde entonces sabíamos que
                  ibamos a pasar el resto de nuestras vidas juntos.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-right">
              <div className="h-100 d-flex flex-column justify-content-center bg-secondary p-4 mr-md-3">
                <h4 className="mb-2">Nos comprometimos</h4>
                <p className="text-uppercase mb-2">3 de febrero de 2023</p>
                <p className="m-0">
                  Tuvimos un momento íntimo rodeado de nuestros amigos en la
                  playa. Fue entonces cuando la GRAN pregunta llegó. ¿Ya se
                  imaginan quién la hizo?
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <img className="img-fluid ml-md-3" src={Story4} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
