import React from "react";

function CustomCountdownRenderer({ days, hours, minutes, seconds, completed }) {
  if (completed) {
    return <span>¡LLEGÓ EL GRAN DÍA!</span>;
  }

  return (
    <div>
      <div className="countdown-container">
      <span className="CountdownStyles" >{days < 10 ? `0 ${days}` : days} DÍAS </span>
      <span className="CountdownStyles" >{hours < 10 ? `0${hours}` : hours} HORAS</span>
      <span className="CountdownStyles" >{minutes < 10 ? `0${minutes}` : minutes} MINS</span>
      <span className="CountdownStyles" >{seconds < 10 ? `0${seconds}` : seconds} SEGS</span>
      </div>
    </div>
  );
}

export default CustomCountdownRenderer;
