import React, { useState } from "react";
import song from "../audio/home-song.mp3"

const MusicPlayer = () => {
  const [audio] = useState(new Audio(song));
  const [playing, setPlaying] = useState(false);

  const togglePlay = () => {
    if (playing) {
      audio.pause();
    } else {
      audio.play();
    }
    setPlaying(!playing);
  };
  const divClassName = playing ? "pause" : "play";

  return (
    <div id="play-pause" onClick={togglePlay} className={divClassName}></div>
  );
};

export default MusicPlayer;
