import React from "react";
import CoupleVideo from "../img/ExampleVideo.mp4";
import AnimatedDiv from "./AnimatedDiv";

const Thanks = () => {
  return (
    <div className="container-fluid py-5" id="thanks">
      <div className="container py-5">
        <AnimatedDiv>
        <div className="section-title position-relative text-center">
          <h6
            className="text-uppercase text-primary mb-3 mt-3"
            style={{ letterSpacing: "3px" }}
          >
            <i class="far fa-smile" style={{ fontSize: "30px" }}></i>
          </h6>
          <h1 className="font-secondary display-4 mb-3">¡Muchas Gracias!</h1>
          <h6
            style={{
              letterSpacing: "2px",
              color: "#474747",
              marginBottom: "20px",
            }}
          >
            por ser parte de este día tan importante para nosotros. También por
            ser parte de nuestra historia y de todo el camino que recorrimos
            hasta aquí. A nuestra familia, amigos y a todos los que estuvieron,
            están y sin dudas sabemos que seguirán estando, muchas gracias!
            Estamos felices de compartirlo con todos ustedes!
          </h6>
          <i className="far fa-heart text-dark"></i>
        </div>
        </AnimatedDiv>
        <div className="row m-0">
          <div className="col-md-12 p-0  text-md-left">
            <div className="h-100 d-flex flex-column">
              <video
                className="couple-video"
                src={CoupleVideo}
                controls
                type="video/mp4"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
