import React from "react";
import AnimatedDiv from "./AnimatedDiv";

const Assistance = () => {
  return (
    <div className="container-fluid bg-assistance confirm-assistance" id="confirm">
      <AnimatedDiv>
      <div className="section-title position-relative text-center">
        <h6
          className="text-uppercase text-primary"
          style={{ letterSpacing: "3px" }}
        >
          <i class="fas fa-clipboard-check" style={{ fontSize: "30px" }}></i>
        </h6>
        <h1 className="font-secondary display-4" style={{ color: "white" }}>
          Confirma tu Asistencia
        </h1>
        <i className="far fa-heart text-white"></i>
      </div>
      </AnimatedDiv>
      <p style={{ color: "white" }}>
        Te pedimos que completes este formulario para confirmar tu presencia
        hasta el día 15 de diciembre de 2023. Gracias!
      </p>
      <a
        target="_blank"
        href="https://forms.gle/j8PU72RfZzEu6pHY6"
        rel="noreferrer"
      >
        <button className="event-button">CONFIRMAR</button>
      </a>
    </div>
  );
};

export default Assistance;
