import React from "react";
import { Carousel } from "react-responsive-carousel";
import Testimonials1 from "../img/bridesmaid-1.jpg";
import Testimonials2 from "../img/bridesmaid-2.jpg";
import Testimonials3 from "../img/bridesmaid-3.jpg";
import AnimatedDiv from "./AnimatedDiv";

const Testimonials = () => {
  return (
    <div className="container-fluid py-" id="about">
      <div className="container py-5">
        <AnimatedDiv>
        <div className="section-title position-relative text-center">
          <h6
            className="text-uppercase text-primary mb-3 mt-3"
            style={{ letterSpacing: "3px" }}
          >
            DESEOS DE
          </h6>
          <h1 className="font-secondary display-4 mb-3">Nuestros Amigos</h1>

          <i className="far fa-heart text-dark"></i>
        </div>
        </AnimatedDiv>
        <div>
          <Carousel
            infiniteLoop={true}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            showArrows={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  onClick={onClickHandler}
                  className="carousel-arrow-testimonials prev"
                >
                  <i
                    className="fas fa-angle-left"
                    style={{ color: "#E47A2E", fontSize: "25px" }}
                  ></i>
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  onClick={onClickHandler}
                  className="carousel-arrow-testimonials next"
                >
                  <i
                    className="fas fa-angle-right"
                    style={{ color: "#E47A2E", fontSize: "25px" }}
                  ></i>
                </button>
              )
            }
          >
            <div>
              <img
                alt=""
                src={Testimonials1}
                style={{ borderRadius: "50%", width: "15%" }}
              />
              <div>
                <p className="mt-3">
                  Estoy convencida que son el uno para el otro. Les deseo mucha
                  felicidad en este nuevo camino!
                </p>
              </div>
            </div>
            <div>
              <img
                alt=""
                src={Testimonials3}
                style={{ borderRadius: "50%", width: "15%" }}
              />
              <p className="mt-3">
                Uno cosecha lo que siembra, y ustedes sembraron mucho amor.
                Estoy ansioso por ser testigo del próximo capítulo de sus vidas
                gracias por seguir compartiendolas con nosotros!
              </p>
            </div>
            <div>
              <img
                alt=""
                src={Testimonials2}
                style={{ borderRadius: "50%", width: "15%" }}
              />
              <p className="mt-3">
                Espero de corazón que todo lo que venga en adelante sea pura
                felicidad. Aquí estamos sus amigos para apoyarlos en cada paso
                que den. Felicidades y a fesjetar!! Los quiero
              </p>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
