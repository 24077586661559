import React from "react";
import MenDresscode from "../img/MenDresscode.jpg";
import Womenresscode from "../img/WomenDresscode.jpg";
import AnimatedDiv from "./AnimatedDiv";

const Dresscode = () => {
  return (
    <div className="container-fluid py-5" id="about">
      <div className="container py-5">
        <AnimatedDiv>
        <div className="section-title position-relative text-center">
          <h1 className="font-secondary display-4 mb-3">Dresscode</h1>
          <i className="far fa-heart text-dark"></i>
        </div>
        </AnimatedDiv>
        <div className="row m-0 mb-4 mb-md-0 pb-2 pb-md-0">
          <div className="col-md-6 p-0 dresscode-container">
            <img
              alt="groom"
              className="w-100 h-100"
              src={MenDresscode}
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-md-6 p-0 dresscode-container">
            <img
              alt="groom"
              className="w-100 h-100"
              src={Womenresscode}
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            className="col-md-12 p-0 text-center text-md-right"
            style={{ marginTop: "20px" }}
          >
            <div
              className="d-flex flex-column justify-content-center bg-secondary p-5"
              style={{ textAlign: "center", height: "10px" }}
            >
              <h6 style={{ letterSpacing: "2px" }}>
                La vestimenta para este gran día será ELEGANTE.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dresscode;
