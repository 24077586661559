import React, { useState, useEffect } from "react";
import Carousel1 from "../img/Carousel1.jpg";
import Carousel2 from "../img/Carousel2.jpg";
import Carousel1320 from "../img/Carousel1-320.png"
import Carousel2320 from "../img/Carousel2-320.png"
import Countdown from "react-countdown";
import CustomCountdownRenderer from "./CustomCountdownRenderer";
import MusicPlayer from "./MusicPlayer";

const Carousel = () => {
  const currentDate = new Date();
  const targetDate = new Date("2024-06-13");
  const timeUntilTarget = targetDate.getTime() - currentDate.getTime();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container-fluid p-0 mb-5 pb-5" id="home">
      <div id="player-container">
        <MusicPlayer />
      </div>
      <div
        id="header-carousel"
        className="carousel slide carousel-fade"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div
            className="carousel-item position-relative active"
            style={{ height: "100vh", minHeight: "400px" }}
          >
            <div>
              <img
                alt="carosusel-image1"
                className="carousel-image"
                src={windowWidth < 768 ? Carousel1320 : Carousel1}
              />
            </div>
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{ maxWidth: "900px" }}>
                <h1 className="display-1 font-secondary text-white mt-n3 mb-md-4">
                  Federico & Valentina
                </h1>
                <div className="d-inline-block border-top border-bottom border-light py-3 px-4">
                  <h3
                    className="text-uppercase font-weight-normal text-white m-0"
                    style={{ letterSpacing: "5px" }}
                  >
                    ¡NOS CASAMOS!
                  </h3>
                </div>
                <div>
                  <Countdown
                    date={currentDate.getTime() + timeUntilTarget}
                    renderer={CustomCountdownRenderer}
                    zeroPadTime={2}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="carousel-item position-relative"
            style={{ height: "100vh", minHeight: "400px" }}
          >
            <img
              alt="carosusel-image2"
              className="carousel-image"
              src={windowWidth < 768 ? Carousel2320 : Carousel2}
            />
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{ maxWidth: "900px" }}>
                <h1 className="display-1 font-secondary text-white mt-n3 mb-md-4">
                  Federico & Valentina
                </h1>
                <div className="d-inline-block border-top border-bottom border-light py-3 px-4">
                  <h3
                    className="text-uppercase font-weight-normal text-white m-0"
                    style={{ letterSpacing: "5px" }}
                  >
                    ¡NOS CASAMOS!
                  </h3>
                </div>
                <div>
                  <Countdown
                    date={currentDate.getTime() + timeUntilTarget}
                    renderer={CustomCountdownRenderer}
                    zeroPadTime={2}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev justify-content-start"
          href="#header-carousel"
          data-slide="prev"
        >
          <div
            className="btn btn-primary px-0"
            style={{ width: "68px", height: "68px" }}
          >
            <span className="carousel-control-prev-icon mt-3"></span>
          </div>
        </a>
        <a
          className="carousel-control-next justify-content-end"
          href="#header-carousel"
          data-slide="next"
        >
          <div
            className="btn btn-primary px-0"
            style={{ width: "68px", height: "68px" }}
          >
            <span className="carousel-control-next-icon mt-3"></span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Carousel;
