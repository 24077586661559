import React from "react";
import Events1 from "../img/Event1.jpg";
import Events2 from "../img/Event2.jpg";
import AnimatedDiv from "./AnimatedDiv";

const Events = () => {
  return (
    <div className="container-fluid py-5" id="events">
      <div className="container py-5">
        <AnimatedDiv>
        <div className="section-title position-relative text-center">
          <h6
            className="text-uppercase text-primary mb-3"
            style={{ letterSpacing: "3px" }}
          >
            LUNES 15 DE ENERO DE 2024
          </h6>
          <h1 className="font-secondary display-4">Eventos</h1>
          <i className="far fa-heart text-dark"></i>
        </div>
        </AnimatedDiv>
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <h5 className="font-weight-normal text-muted mb-3 pb-3"></h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 border-right border-primary">
            <div className="text-center text-md-right mr-md-3 mb-4 mb-md-0">
              <img
                className="img-fluid mb-4"
                src={Events1}
                alt=""
                style={{ height: "250px", width: "470px" }}
              />
              <h4 className="mb-3">Ceremonia</h4>
              <p className="mb-2">Parroquia San Martin de Porres</p>
              <p className="mb-0">20:00 HS</p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://goo.gl/maps/MHqAc5v1vjRHL4T88?coh=178573&entry=tt"
              >
                <button className="event-button">CÓMO LLEGAR</button>
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-center text-md-left ml-md-3">
              <img
                className="img-fluid mb-4"
                src={Events2}
                alt=""
                style={{ height: "250px", width: "470px" }}
              />
              <h4 className="mb-3">Fiesta</h4>
              <p className="mb-2">Salón de fiestas KEKÉ</p>
              <p className="mb-0">21:30 HS</p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://goo.gl/maps/3u8R7y7e3ZZMyGMC7?coh=178573&entry=tt"
              >
                <button className="event-button">CÓMO LLEGAR</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
