import React from 'react';
import WhatsAppLogo from "../img/wsp.png"

const Footer = () => {
  return (
    <div className="container-fluid" id="about">
      <div className="container py-5">
      <div className="col-md-12 text-center">
            <p>
              <small className="block">
                Created by {" "}
                <a href="https://wa.me/543815496469" target="_blank" rel="noreferrer">
                  Invitapp
                  <img src={WhatsAppLogo} className="contact-image" />
                </a>
                <div>&copy; 2023. All Rights Reserved.</div>
              </small>
            </p>
          </div>
      </div>
    </div>
  )
}

export default Footer